let orderWallMobile = {

    setup : function() {
        this.$element = $('.wallBox'); 
        this.bindEvents();
    },


    bindEvents:  function()  {
        if(isMobile()){
            this.addCssInline();
        }
        else {
            this.removeCssInline();
        }
    },

    addCssInline : function () {
        this.$element.each(function (){
            let newOrder= $(this).attr('data-order-css');
            $(this).attr("style", "order:"+ newOrder );
            //console.log( $(this).find('.wallBox__title').text() +"-"+ $(this).data('order-dom'));
        });
    },

    removeCssInline : function () {
        this.$element.each(function () {
            $(this).removeAttr("style");
        });
    },
 
    init: function () {
      try {
        this.setup();
        this.bindEvents();
      }
      catch (e) {
        console.error(e);
      } 
    }
  };

$(document).ready(function() {
    orderWallMobile.init();

    $(window).resize(function(){
        orderWallMobile.init();
    });
});